<template>
  <div class="flex">
    <div class="bg-mask"></div>
    <div class="bg-login"></div>
    <div class="m-auto mt-52 w-1/2 z-10 ">
      <img src="../assets/images/logo/logo.png" alt="">
    </div>
    <div class="blur-box">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <validation-provider name="نام کاربری" rules="required" v-slot="{ errors }">
            <label class="block relative">
              <span class="text-white opacity-50 text-sm">نام کاربری</span>
              <input
                  type="text"
                  class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
                  v-model="username"
              >
              <span class="absolute top-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.767" height="12.767" viewBox="0 0 12.767 12.767">
            <path id="ic_person_24px"
                  d="M10.384,10.384A3.192,3.192,0,1,0,7.192,7.192,3.191,3.191,0,0,0,10.384,10.384Zm0,1.6C8.253,11.98,4,13.049,4,15.171v1.6H16.767v-1.6C16.767,13.049,12.514,11.98,10.384,11.98Z"
                  transform="translate(-4 -4)" fill="#ff7400"/>
            </svg>
           </span>
            </label>
            <span class="text-sm text-red-500 text-right inline-block ">{{ errors[0] }}</span>

          </validation-provider>
          <validation-provider name="رمز عبور" rules="required" v-slot="{ errors } ">
            <label class="block relative mt-7">
              <span class="text-white opacity-50 text-sm">رمز عبور</span>
              <input
                  v-model="password"
                  :type="passwordVisible ? 'text' : 'password'"
                  class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white">
              <span class="absolute top-8" @click="passwordVisible = !passwordVisible">
          <svg v-if="!passwordVisible" id="eye-view" xmlns="http://www.w3.org/2000/svg" width="16.404" height="14.248"
               viewBox="0 0 16.404 14.248">
            <g id="Group_86" data-name="Group 86" transform="translate(0 1.749)">
              <path id="Path_160" data-name="Path 160"
                    d="M14.136,97.074A8.568,8.568,0,0,1,8,99.552a8.607,8.607,0,0,1-6.139-2.478,8.956,8.956,0,0,1-1.812-2.5.54.54,0,0,1,0-.451,7.655,7.655,0,0,1,1.765-2.347,8.944,8.944,0,0,1,6.242-2.3,8.747,8.747,0,0,1,6.186,2.309,7.255,7.255,0,0,1,1.718,2.356.532.532,0,0,1-.009.432A8.73,8.73,0,0,1,14.136,97.074Zm-6.054-6.43a2.741,2.741,0,1,0,2.741,2.741A2.744,2.744,0,0,0,8.082,90.645Z"
                    transform="translate(0.002 -89.481)" fill="#ff7500"/>
            </g>
            <path id="Path_2136" data-name="Path 2136" d="M0,16.615H1.352L2.059,0H.707Z"
                  transform="translate(4.656 13.204) rotate(-135)" fill="#fff"/>
            <path id="Path_2137" data-name="Path 2137" d="M0,18.091H1.352L2.059,0H.707Z"
                  transform="translate(2.007 14.248) rotate(-135)" fill="#ff7500"/>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="10.071" viewBox="0 0 16 10.071">
  <path id="Path_160" data-name="Path 160"
        d="M14.136,97.074A8.568,8.568,0,0,1,8,99.552a8.607,8.607,0,0,1-6.139-2.478,8.956,8.956,0,0,1-1.812-2.5.54.54,0,0,1,0-.451,7.655,7.655,0,0,1,1.765-2.347,8.944,8.944,0,0,1,6.242-2.3,8.747,8.747,0,0,1,6.186,2.309,7.255,7.255,0,0,1,1.718,2.356.532.532,0,0,1-.009.432A8.73,8.73,0,0,1,14.136,97.074Zm-6.054-6.43a2.741,2.741,0,1,0,2.741,2.741A2.744,2.744,0,0,0,8.082,90.645Z"
        transform="translate(0.002 -89.481)" fill="#ff7500"/>
</svg>
        </span>
            </label>
            <span class="text-sm text-red-500 text-right inline-block ">{{ errors[0] }}</span>
          </validation-provider>
          <router-link class="text-white text-left text-xs mt-1 w-full inline-block" :to="{name : 'RealEstateForget'}">
            رمز عبورتو فراموش کردی؟
          </router-link>
          <button type="submit" class="btn-custom  mt-11 w-full">ورود
            <span class="svg-btn-icon bg-orange">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 24.826) rotate(-90)" fill="#fafafa"/>
</svg>

        </span>
          </button>
        </form>
      </ValidationObserver>
      <p class="text-white text-xs text-center mt-11">حساب کاربری ندارید؟<router-link :to="{name : 'RealEstateVerify'}" class="text-yellow-400 text-base"> ثبت نام کنید</router-link></p>
    </div>
  </div>


</template>

<script>
import axios from '../plugins/axios'
export default {
  name: "RealEstateLogin",
  data() {
    return {
      username : null,
      password : null,
      passwordVisible : false
    }
  },
  methods:{
    onSubmit(){
      axios.post('/login',{
        username : this.username,
        password : this.password
      }).then(async ({data})=>{
        if (!data.status){
          this.$toast.error(data.message)
        }else if (data.status && data.data.login === true){
          await localStorage.setItem('user-token',data.data.token)
          await axios.get('/users').then(async ({data})=>{
            await localStorage.setItem('user', JSON.stringify(data.data))
            await this.$router.push({name : 'HomeRealEstate'})

          })
        }
      }).catch(err =>{
        this.$toast.error(err.message)
      })
    }
  }
}
</script>

<style scoped>

</style>